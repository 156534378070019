import '@moodys-bss/reset-css';
import { LicenseManager } from 'ag-grid-enterprise';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app/App';
import { store } from './app/store';
import { Auth0ProviderWithHistory } from './auth/Auth0ProviderWithHistory';
import './index.css';
import 'whatwg-fetch';
import { patchHistoryMethod } from './lib/history-utils/patch-history-method';
import * as serviceWorker from './serviceWorker';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-048080 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Moodys Analytics UK Limited )_is_granted_a_( Multiple Applications )_Developer_License_for_( 19 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 3 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 23 January 2025 )____[v2]_MTczNzU5MDQwMDAwMA==c51f19101276dae736478b53f3c297b5',
);

// patching of history methods
// this is required because this application is using 'react-use' package
// and some functionality from it e.g. useSearchParam are requiring this to be done
patchHistoryMethod('pushState');
patchHistoryMethod('replaceState');

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Router>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Router>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
